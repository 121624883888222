import { DebtStatusesEnum, DebtSourceTypesEnum } from '~/shared/config/enums'
import { myPalette } from '~/shared/lib/theme'

export const AUTH_TOKEN_STORAGE_KEY = 'auth_token'
export const AUTH_EMAIL_STORAGE_KEY = 'auth_email'
export const AUTH_CURRENT_USER_ID_STORAGE_KEY = 'auth_current_user_id'
export const USER_PERMISSIONS_STORAGE_KEY = 'user_permissions'
export const UI_SIDEBAR_WIDTH = 208

export const MIN_COLUMN_WIDTH = 90

export type Option = { id: string | number; label: string | number }
export type OptionEnum<T> = { id: T; label: string }

export const debtStatusEnumLabels: Record<DebtStatusesEnum, string> = {
  [DebtStatusesEnum.PAID]: 'Завершено',
  [DebtStatusesEnum.PROCESSING]: 'Выполняется',
  [DebtStatusesEnum.SUSPENDED]: 'Приостановлено',
}

export const debtStatusEnumOptions: OptionEnum<DebtStatusesEnum>[] =
  Object.keys(DebtStatusesEnum).map((value) => {
    const label = debtStatusEnumLabels[value as keyof typeof DebtStatusesEnum]
    return {
      label,
      id: value as DebtStatusesEnum,
    }
  })

export const debtTypeEnumLabels: Record<DebtSourceTypesEnum, string> = {
  [DebtSourceTypesEnum.INSPECTION_VIOLATIONS]: 'Нарушения Компании',
  [DebtSourceTypesEnum.INSPECTION_DAMAGES]: 'Повреждения',
  [DebtSourceTypesEnum.PLAN_INSPECTION_DELAY]: 'Просрочка ПО',
  [DebtSourceTypesEnum.EXTERNAL_PENALTY]: 'Штрафы ГИБДД',
  [DebtSourceTypesEnum.BALANCE_CORRECTION]: 'Коррекция баланса',
  [DebtSourceTypesEnum.TOLL_ROAD]: 'Платный проезд',
  [DebtSourceTypesEnum.REDEMPTION_PAYMENT]: 'Выкупной платеж',
}

export const debtTypeEnumOptions: OptionEnum<DebtSourceTypesEnum>[] =
  Object.keys(DebtSourceTypesEnum).map((value) => {
    const label = debtTypeEnumLabels[value as keyof typeof DebtSourceTypesEnum]
    return {
      label,
      id: value as DebtSourceTypesEnum,
    }
  })

export const STATUS_VARIANT = {
  green: {
    backgroundColor: myPalette.green['100'],
    color: myPalette.green['600'],
  },
  red: {
    backgroundColor: myPalette.red['100'],
    color: myPalette.red['600'],
  },
  gray: {
    backgroundColor: myPalette.gray['100'],
    color: myPalette.gray['700'],
  },
  yellow: {
    backgroundColor: myPalette.yellow['100'],
    color: myPalette.yellow['600'],
  },
}
