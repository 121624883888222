export enum DebtStatusesEnum {
  PROCESSING = 'PROCESSING',
  PAID = 'PAID',
  SUSPENDED = 'SUSPENDED',
}

export enum DebtSourceTypesEnum {
  INSPECTION_VIOLATIONS = 'INSPECTION_VIOLATIONS',
  INSPECTION_DAMAGES = 'INSPECTION_DAMAGES',
  PLAN_INSPECTION_DELAY = 'PLAN_INSPECTION_DELAY',
  EXTERNAL_PENALTY = 'EXTERNAL_PENALTY',
  BALANCE_CORRECTION = 'BALANCE_CORRECTION',
  TOLL_ROAD = 'TOLL_ROAD',
  REDEMPTION_PAYMENT = 'REDEMPTION_PAYMENT',
}

export enum EventAuditEnum {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  RESTORED = 'restored',
}
